import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { differenceInCalendarDays } from "date-fns";

import { instagramId, messengerId, userPhone } from "./settings";
import { Chat, CommentReaction, ConversationType, Post } from "@@types/chat";
import { Message } from "@@types/message";
import { Contact, Tag } from "@@types/contacts";
import {
  deleteFacebookComment,
  finishedChatApi,
  getChatsService,
  getConversationByPhoneService,
  getMsgsService,
  getSearchChatsService,
  getSearchMsgsService,
  handleConsultationAnsweredApi,
} from "@@services/ChatService";
import { apiGetContacts } from "@@services/CrmService";
import { TeamMember } from "@@types/teamMember";
import { AppThunk, RootState } from "../store";
import { CompanyMessages } from "@@types/companyMessages";
import apiService from "../apiService";
import RtkQueryService from "@@services/RtkQueryService";
import { socket } from "@@socket/webSocket";
import { selectUser } from "./userSlice";
import { putContact } from "./contactsSlice";
import { M } from "vite/dist/node/types.d-aGj9QkWt";
import { Consultation } from "@@types/consultation";

type MenuType = "docs" | "media" | "contact";

export type Filters = {
  search?: string;
  product?: string;
  filterUnRead?: boolean;
  dateFrom?: string;
  dateTo?: string;
  tags?: Array<string>;
  page?: number;
  pageChatsSearch?: number;
  pageContactsSearch?: number;
  pageMessagesSearch?: number;
  finished?: boolean;
  assignToMe?: boolean;
  consultations?: boolean;
  notAnswered?: boolean;
  conversationsTypes?: Array<ConversationType>;
};

export type ChatState = {
  openSideNav?: boolean;
  activeChat?: Chat & { metaTag?: string };
  activeChatConsultation?: Chat & { metaTag?: string };
  msgModal?: number;
  msgInView?: Message;
  messages: Array<Message>;
  chats: Array<Chat>;
  consultations: Array<Chat>;
  consultationsLoading: boolean;
  consultationsMessages: Array<Message>;
  disableConsultationsFectch: boolean;
  chatsSearch: {
    conversations: Array<Chat>;
    contacts: Array<Contact>;
    messages: Array<Message>;
  };
  consultationsSearch: {
    conversations: Array<Chat>;
    contacts: Array<Contact>;
    messages: Array<Message>;
  };
  contactsSearch: Array<Contact>;
  // contacts: Array<Contact>
  selectedFiles?: Array<{ file: File; text_body: string }>;
  menuSelected?: MenuType;
  loadingMessages: boolean;
  loadingConsultationsMessages: boolean;
  chatsLoading: boolean;
  disableChatFectch: boolean;
  disableChatSearchFectch: {
    disableChatSearchFectch?: boolean;
    disableContactsSearchFectch?: boolean;
    disableMessagesSearchFectch?: boolean;
  };
  disableConsultationsSearchFectch: {
    disableChatSearchFectch?: boolean;
    disableContactsSearchFectch?: boolean;
    disableMessagesSearchFectch?: boolean;
  };

  filters: Filters;
  filtersConsultations: Filters;
  searchType: "contacts" | "chat";
};
const initialState: ChatState = {
  openSideNav: true,
  chats: [],
  chatsSearch: {
    contacts: [],
    conversations: [],
    messages: [],
  },
  consultationsSearch: {
    contacts: [],
    conversations: [],
    messages: [],
  },
  contactsSearch: [],
  messages: [],
  selectedFiles: [],
  consultations: [],
  consultationsMessages: [],
  loadingMessages: false,
  loadingConsultationsMessages: false,
  chatsLoading: false,
  disableChatFectch: false,
  consultationsLoading: false,
  disableConsultationsFectch: false,

  searchType: "chat",
  filters: {
    search: "",
    filterUnRead: false,
    assignToMe: false,
    product: "",

    tags: [],
    conversationsTypes: [],
    page: 1,
    pageChatsSearch: 1,
    pageContactsSearch: 1,
    pageMessagesSearch: 1,
  },
  filtersConsultations: {
    search: "",
    filterUnRead: false,
    product: "",
    tags: [],
    page: 1,
    pageChatsSearch: 1,
    pageContactsSearch: 1,
    pageMessagesSearch: 1,
  },
  disableChatSearchFectch: {
    disableChatSearchFectch: false,
    disableContactsSearchFectch: false,
    disableMessagesSearchFectch: false,
  },
  disableConsultationsSearchFectch: {
    disableChatSearchFectch: false,
    disableContactsSearchFectch: false,
    disableMessagesSearchFectch: false,
  },
};
export const getMsgsApi = createAsyncThunk(
  "chat/getMsgs",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    let activeChatData = activeChat(state);

    if (!activeChatData.id) {
      try {
        const res = await getConversationByPhoneService(activeChatData.contact);
        activeChatData = res.data;
        dispatch(setActiveChat(activeChatData));
      } catch (error) {}
    }
    const chatMsgs = chatMessages(state);
    const lastMsgId = chatMsgs.length
      ? chatMsgs[chatMsgs.length - 1].id
      : undefined;

    const msgs = await getMsgsService({
      id: activeChatData?.id,
      msgId: lastMsgId,
      product: activeChatData.product,
    });
    return { msgs, activeChat: activeChatData };
  }
);
export const getSubCommentsApi = createAsyncThunk(
  "chat/getSubCommentsApi",
  async (parentId: string, { getState }) => {
    const state = getState() as RootState;
    const activeChatData = activeChat(state);
    const chatMsgs = chatCommentsById(state, parentId);
    const lastMsgId = chatMsgs.length
      ? chatMsgs[chatMsgs.length - 1].id
      : undefined;

    const msgs = await getMsgsService({
      id: activeChatData?.id,
      msgId: lastMsgId,
      product: activeChatData.product,
      parentComment: parentId,
    });
    return { msgs, parentId };
  }
);
export const getMsgsConsultationsApi = createAsyncThunk(
  "chat/getMsgsConsultations",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const activeChatData = activeChatConsultation(state);
    const chatMsgs = consultationsMessages(state);
    const lastMsgId = chatMsgs.length
      ? chatMsgs[chatMsgs.length - 1].id
      : undefined;

    const msgs = await getMsgsService({
      id: activeChatData?.id,
      msgId: lastMsgId,
      product: activeChatData.product,
      consultation: true,
    });
    return { msgs, activeChatId: activeChatData?.id };
  }
);

export const getChatByPhoneApi = createAsyncThunk(
  "chat/getChatByPhoneApi",
  async (contact: Contact, { rejectWithValue }) => {
    try {
      const res = await getConversationByPhoneService(contact);
      return res.data;
    } catch (error) {
      return rejectWithValue(contact);
    }
  }
);

export const getChatsApi = createAsyncThunk(
  "chat/getChats",
  async ({
    lastMsgDate,
    chatsIdFilter,
    unRead,
  }: {
    lastMsgDate?: string;
    chatsIdFilter?: Array<number>;
    unRead?: boolean;
  }) => {
    return await getChatsService(lastMsgDate, chatsIdFilter, unRead);
  }
);
export const getConsultationsApi = createAsyncThunk(
  "chat/getConsultations",
  async ({
    lastMsgDate,
    chatsIdFilter,
    unRead,
  }: {
    lastMsgDate?: string;
    chatsIdFilter?: Array<number>;
    unRead?: boolean;
  }) => {
    return await getChatsService(lastMsgDate, chatsIdFilter, unRead, true);
  }
);

export const getContactsFilteredApi = createAsyncThunk(
  "chat/getContactsFilteredApi",
  async ({
    search,
    dateFrom,
    dateTo,
    tags,
    page,
  }: {
    search?: string;
    dateFrom?: string;
    dateTo?: string;
    tags?: string[];
    page?: number;
  }) => {
    return await apiGetContacts<{ contacts: Contact[] }>({
      from: dateFrom,
      to: dateTo,
      search,
      tags,
      page,
    });
  }
);

export const getSearchChatsContactsApi = createAsyncThunk(
  "chat/getSearchChatsContactsApi",
  async (_, { getState }) => {
    const filters = chatFilters(getState() as any);
    const disableChatFectch = disableChatSearchFectch(getState() as any);
    return await getSearchChatsService(filters, disableChatFectch);
  }
);
export const getSearchChatsMessagesConsultationsApi = createAsyncThunk(
  "chat/getSearchChatsMessagesConsultationsApi",
  async (_, { getState }) => {
    const filters = filtersConsultations(getState() as any);
    const disableChatFectch = disableConsultationsSearchFectch(
      getState() as any
    );
    return await getSearchChatsService(filters, disableChatFectch, true);
  }
);
export const finishedChat = createAsyncThunk(
  "chat/finishedChat",
  async (_, { getState }) => {
    const chat = activeChat(getState() as any);

    return await finishedChatApi(chat!.id);
  }
);

export const handleConsultationAnswered = createAsyncThunk(
  "chat/handleConsultationAnswered",
  async (msg: Message, { getState }) => {
    return await handleConsultationAnsweredApi(msg?.consultation?.id);
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    handleSideNav(state, action: PayloadAction<boolean>) {
      state.openSideNav = action.payload;
      if (action.payload) {
        state.activeChat = undefined;
      }
    },
    setSearchType(state, action: PayloadAction<"contacts" | "chat">) {
      state.searchType = action.payload;
    },

    setMsgModal(state, action: PayloadAction<Message | undefined>) {
      state.msgModal = action.payload?.id;
    },

    setActiveChat(state, action: PayloadAction<Chat | undefined>) {
      state.activeChat = { ...action.payload };
    },
    setactiveChatConsultation(state, action: PayloadAction<Chat | undefined>) {
      state.activeChatConsultation = { ...action.payload };
    },
    setChatFilters(
      state,
      action: PayloadAction<{
        [K in keyof Filters]?: Filters[K];
      }>
    ) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setConsultationsFilters(
      state,
      action: PayloadAction<{
        [K in keyof Filters]?: Filters[K];
      }>
    ) {
      state.filtersConsultations = {
        ...state.filtersConsultations,
        ...action.payload,
      };
    },
    zeroingUnReadMsgsChat(
      state,
      action: PayloadAction<Chat & { user: TeamMember }>
    ) {
      const index = state.chats.findIndex(
        (chat) => chat.id === action.payload?.id
      );
      if (index !== -1 && state.chats[index]?.unReadMsgs) {
        state.chats[index]!.unReadMsgs![action.payload.user.username] = 0;
        state.chats[index]!.isBotReply = false;
      }
      const indexSearch = state.chatsSearch.conversations.findIndex(
        (chat) => chat.id === action.payload?.id
      );

      if (
        indexSearch !== -1 &&
        state.chatsSearch.conversations[indexSearch].unReadMsgs
      ) {
        state.chatsSearch.conversations[indexSearch]!.unReadMsgs![
          action.payload.user.username
        ] = 0;
        state.chatsSearch.conversations[indexSearch]!.isBotReply = false;
      }
    },
    updateChatBotReplt(
      state,
      action: PayloadAction<{ chat: Chat; value: boolean }>
    ) {
      const index = state.chats.findIndex(
        (chat) => chat.id === action.payload?.chat.id
      );

      if (index !== -1) {
        state.chats[index]!.isBotReply = action.payload.value;
      }
      const indexSearch = state.chatsSearch.conversations.findIndex(
        (chat) => chat.id === action.payload?.chat.id
      );
      if (indexSearch !== -1) {
        state.chatsSearch.conversations[indexSearch]!.isBotReply =
          action.payload.value;
      }
    },

    addMessage(
      state,
      action: PayloadAction<Partial<Message & { to?: string }>>
    ) {
      const index = state.chats.findIndex(
        (chat) => chat.id === state.activeChat?.id
      );
      const indexSearch = state.chatsSearch.conversations.findIndex(
        (chat) => chat.id === state.activeChat?.id
      );
      const indexConsultation = state.consultations.findIndex(
        (chat) => chat.id === state.activeChatConsultation?.id
      );

      const msgData = {
        ...action.payload,
        contacts: action.payload.showInConsultation
          ? state?.activeChatConsultation?.contact
          : state?.activeChat?.contact,
        created: new Date().toISOString(),
      } as Message;

      state.messages.unshift(msgData);

      if (msgData.showInConsultation) {
        state.consultationsMessages.unshift(msgData);
      }

      if (index !== -1) {
        state.chats[index].replyFor = undefined;
        state.chats[index].lastMessage = msgData;
        state.chats[index].lastMessageDate = new Date().toISOString();
      }
      if (indexSearch !== -1) {
        state.chatsSearch.conversations[indexSearch].replyFor = undefined;
        state.chatsSearch.conversations[indexSearch].lastMessage = msgData;
        state.chatsSearch.conversations[indexSearch].lastMessageDate =
          new Date().toISOString();
      }
      if (indexConsultation !== -1) {
        state.consultations[indexConsultation].replyFor = undefined;
        state.consultations[indexConsultation].lastMessage = msgData;
        state.consultations[indexConsultation].lastMessageDate =
          new Date().toISOString();
      }

      if (state.activeChat) {
        state.activeChat.replyFor = undefined;
        state.activeChat.lastMessage = msgData;
      }
      if (state.activeChatConsultation) {
        state.activeChatConsultation.replyFor = undefined;
        state.activeChatConsultation.lastMessage = msgData;
      }
    },
    addCompanyMessage(state, action: PayloadAction<Message>) {
      const payload = action.payload;

      // const companyMsgJson = action.payload.jsondata
      // const msgType = action.payload.msgtype

      const index = state.chats.findIndex(
        (chat) => chat.contact.waId === state.activeChat?.contact?.waId
      );
      const indexSearch = state.chatsSearch.conversations.findIndex(
        (chat) => chat.contact.waId === state.activeChat?.contact?.waId
      );

      state.messages.unshift({
        ...payload,
      });
      if (index !== -1) {
        state.chats[index] = {
          ...payload.conversation,
          lastMessage: { ...payload },
        };
      }
      if (indexSearch !== -1) {
        state.chatsSearch.conversations[indexSearch] = {
          ...payload.conversation,
          lastMessage: { ...payload },
        };
      }
    },

    addReactionMsg(
      state,
      action: PayloadAction<{
        emoji: string;
        message_id: string;
        message_from: string;
      }>
    ) {
      const msg = state.messages.find(
        (msg) => msg.meta_message_id === action.payload.message_id
      );

      if (msg) {
        const currentuserReaction = msg.reaction_emoji?.find(
          (r) => r.from === action.payload.message_from
        );
        if (currentuserReaction) {
          msg.reaction_emoji = msg.reaction_emoji?.filter(
            (r) => r.from !== action.payload.message_from
          );
        }
        msg.reaction_emoji = [
          ...(msg.reaction_emoji ?? []),
          {
            emoji: action.payload.emoji,
            from: action.payload.message_from,
          },
        ];
      }
    },

    removeReaction(
      state,
      action: PayloadAction<{
        message_id: string;
        message_from: string;
      }>
    ) {
      const msg = state.messages.find(
        (msg) => msg.meta_message_id === action.payload.message_id
      );

      if (msg) {
        msg.reaction_emoji = msg.reaction_emoji?.filter(
          (r) => r.from !== action.payload.message_from
        );
      }
    },

    addChat(state, action: PayloadAction<Partial<Chat>>) {
      const chatData = {
        ...action.payload,
      } as Chat;

      // state.chats.unshift(chatData);
      state.activeChat = chatData;
    },
    conversationAssigned(state, action: PayloadAction<Partial<Chat>>) {
      const existing = state.chats.find(
        (chat) => chat.contact.waId === action.payload.contact?.waId
      );
      if (!existing) {
        const chatData = {
          ...action.payload,
        } as Chat;

        state.chats.unshift(chatData);
      }
    },
    conversationUnAssigned(state, action: PayloadAction<Partial<Chat>>) {
      const existing = state.chats.find(
        (chat) => chat.contact.waId === action.payload.contact?.waId
      );

      if (existing) {
        state.chats = state.chats.filter(
          (chat) => chat.contact.waId !== action.payload.contact?.waId
        );
      }
      if (state.activeChat?.contact.waId === action.payload.contact?.waId) {
        state.activeChat = undefined;
      }
    },
    updateChatContact(state, action: PayloadAction<Partial<Contact>>) {
      const index = state.chats.findIndex(
        (chat) => chat.contact.id === action.payload.id
      );
      if (index !== -1) {
        state.chats[index].contact = {
          ...state.chats[index].contact,
          ...action.payload,
        };
        const activeChat = state.activeChat;
        if (activeChat && activeChat.contact.id === action.payload.id) {
          activeChat.contact = { ...activeChat.contact, ...action.payload };
        }
      }
      const indexSearch = state.chatsSearch.conversations.findIndex(
        (chat) => chat.contact.id === action.payload.id
      );
      if (indexSearch !== -1) {
        state.chatsSearch.conversations[index].contact = {
          ...state.chatsSearch.conversations[index].contact,
          ...action.payload,
        };
        const activeChat = state.activeChat;
        if (activeChat && activeChat.contact.id === action.payload.id) {
          activeChat.contact = { ...activeChat.contact, ...action.payload };
        }
      }
    },
    setReplayMsg(state, action: PayloadAction<Message | undefined>) {
      const chat = state.chats.find((chat) => chat.id === state.activeChat?.id);
      const chatConsultation = state.consultations.find(
        (chat) => chat.id === state.activeChatConsultation?.id
      );
      const chatSearch = state.chatsSearch.conversations.find(
        (chat) => chat.id === state.activeChat?.id
      );

      if (chatConsultation) chatConsultation.replyFor = action.payload;
      if (chat) chat.replyFor = action.payload;
      if (chatSearch) chatSearch.replyFor = action.payload;
      if (state.activeChat) {
        state.activeChat.replyFor = action.payload;
      }
      if (state.activeChatConsultation) {
        state.activeChatConsultation.replyFor = action.payload;
      }
    },
    setFiles(
      state,
      action: PayloadAction<Array<{ file: File; text_body: string }>>
    ) {
      state.selectedFiles = action.payload;
    },
    setMenuSelected(state, action: PayloadAction<MenuType | undefined>) {
      state.menuSelected = action.payload;
    },
    setMsgInView(state, action: PayloadAction<Message | undefined>) {
      state.msgInView = action.payload;
    },
    setAssignTo(state, action: PayloadAction<Array<TeamMember> | undefined>) {
      const chat = state.chats.find(
        (chat) => chat.contact.waId === state.activeChat?.contact?.waId
      );
      const chatSearch = state.chatsSearch.conversations.find(
        (chat) => chat.contact.waId === state.activeChat?.contact?.waId
      );

      if (chat) chat.handledBy = action.payload;
      if (chatSearch) chatSearch.handledBy = action.payload;
      if (state.activeChat) state.activeChat.handledBy = action.payload;
    },
    setChats(state, action: PayloadAction<Array<Chat>>) {
      state.chats = action.payload.map((chat) => ({
        ...chat,
        messages: [],
      }));
    },
    receiveMsg(
      state,
      action: PayloadAction<
        Message & { companyPhone: string; user: TeamMember }
      >
    ) {
      const chatPhone = action.payload.conversation?.contact.waId;
      const chatMessenger = action.payload.conversation?.contact?.messenger_id;
      const chatInstagram = action.payload.conversation?.contact?.instagram_id;

      const chat = state.chats.find((chat) => {
        switch (action.payload.product) {
          case "whatsapp":
          case "sms":
            return chat?.contact?.waId === chatPhone;
          case "messenger":
            return chat?.contact?.messenger_id === chatMessenger;
          case "instagram":
            return chat?.contact?.instagram_id === chatInstagram;
        }
      });
      const chatSearch = state.chatsSearch.conversations.find((chat) => {
        switch (action.payload.product) {
          case "whatsapp":
          case "sms":
            return chat.contact.waId === chatPhone;
          case "messenger":
            return chat.contact.messenger_id === chatMessenger;
          case "instagram":
            return chat.contact.instagram_id === chatInstagram;
        }
      });

      const msgIndex = state?.messages.findIndex(
        (msg) => msg.id === action.payload.id
      );

      if (msgIndex === -1) {
        state.messages.unshift(action.payload);
      } else {
        state.messages[msgIndex] = action.payload;
      }

      if (chat) {
        chat.lastMessage = action.payload;
        chat.endSession = action.payload.conversation?.endSession;
        chat.unReadMsgs = action.payload.conversation?.unReadMsgs;
        chat.lastMessageDate = action.payload.created;
      } else {
        state.chats.unshift({
          ...action.payload.conversation,
          lastMessage: action.payload,
          lastMessageDate: action.payload.created,
        } as Chat);
        if (state.chats.length > 100 && action.payload.bulkMsg) {
          state.chats = state.chats.slice(0, 100);
        }
      }
      if (chatSearch) {
        chatSearch.lastMessage = action.payload;
        chatSearch.endSession = action.payload.conversation?.endSession;
        chatSearch.unReadMsgs = action.payload.conversation?.unReadMsgs;
        chatSearch.lastMessageDate = action.payload.created;
      } else {
        const {
          dateFrom,
          dateTo,
          filterUnRead,
          finished,
          search,
          tags,
          assignToMe,
          product,
        } = state.filters;

        const sameProduct = action.payload.product === product || !product;
        if (
          !dateFrom &&
          !dateTo &&
          !finished &&
          !assignToMe &&
          !search &&
          !tags.length &&
          (filterUnRead || sameProduct)
        ) {
          state.chatsSearch.conversations.unshift({
            ...action.payload.conversation,
            lastMessage: action.payload,
            lastMessageDate: action.payload.created,
          } as Chat);
        }
      }

      if (
        state.activeChat &&
        (chatPhone === state.activeChat?.contact.waId ||
          state.activeChat?.contact.messenger_id === chatMessenger)
      ) {
        state.activeChat.lastMessage = action.payload;
        state.activeChat.metaTag = undefined;
        state.activeChat.endSession = action.payload.conversation?.endSession;
      }
      // const activeChatContact = state.activeChat?.contact?.waId
      // const newChat = state.chats.find(
      //     (chat) => chat.contact.waId === chatPhone,
      // )

      // if (action.payload.sendBy) {
      //     newChat!.unReadMessages = 0
      // } else if (
      //     !action.payload.sendBy &&
      //     action.payload.message_from !== action.payload.companyPhone &&
      //     activeChatContact !== action.payload.contacts?.waId
      // ) {
      //     newChat!.unReadMessages += 1
      // }
      const chatConsultations = state.consultations.find((chat) => {
        switch (action.payload.product) {
          case "whatsapp":
          case "sms":
            return chat.contact.waId === chatPhone;
          case "messenger":
            return chat.contact.messenger_id === chatMessenger;
          case "instagram":
            return chat.contact.instagram_id === chatInstagram;
        }
      });
      const msgIndexConsultation = state.consultationsMessages.findIndex(
        (msg) => msg.id === action.payload.id
      );

      if (action.payload.showInConsultation) {
        if (msgIndexConsultation !== -1) {
          state.consultationsMessages[msgIndex] = {
            ...state.consultationsMessages[msgIndex],
            ...action.payload,
          };
        } else {
          state.consultationsMessages.unshift(action.payload);
        }
      }
      if (
        chatConsultations &&
        action.payload.showInConsultation &&
        new Date(chatConsultations?.lastMessage?.created) <
          new Date(action.payload.created)
      ) {
        chatConsultations.lastMessage = {
          ...chatConsultations.lastMessage,
          ...action.payload,
        };
        chatConsultations.lastConsultationDate =
          action.payload.conversation.lastConsultationDate;
        chatConsultations.consultationCount =
          action.payload.conversation.consultationCount;
      }

      const consultationsSearch = state.consultationsSearch.conversations.find(
        (chat) => {
          switch (action.payload.product) {
            case "whatsapp":
            case "sms":
              return chat.contact.waId === chatPhone;
            case "messenger":
              return chat.contact.messenger_id === chatMessenger;
            case "instagram":
              return chat.contact.instagram_id === chatInstagram;
          }
        }
      );

      if (consultationsSearch) {
        consultationsSearch.lastMessage = action.payload;
        consultationsSearch.endSession =
          action.payload.conversation?.endSession;
        consultationsSearch.unReadMsgs =
          action.payload.conversation?.unReadMsgs;
        consultationsSearch.lastMessageDate = action.payload.created;
      } else if (
        action.payload.showInConsultation ||
        action.payload.consultationTag
      ) {
        const {
          dateFrom,
          dateTo,
          filterUnRead,
          finished,
          search,
          tags,
          assignToMe,
          product,
        } = state.filtersConsultations;

        const sameProduct = action.payload.product === product || !product;
        if (
          !dateFrom &&
          !dateTo &&
          !finished &&
          !assignToMe &&
          !search &&
          !tags.length &&
          (filterUnRead || sameProduct)
        ) {
          state.consultationsSearch.conversations.unshift({
            ...action.payload.conversation,
            lastMessage: action.payload,
            lastMessageDate: action.payload.created,
          } as Chat);
        }
      }
    },
    updateMsg(state, action: PayloadAction<Message>) {
      const chatId = action.payload.conversation?.id;

      const chat = state.chats.find((chat) => chat.id === chatId);
      const chatConsultations = state.consultations.find(
        (chat) => chat.id === chatId
      );
      const chatSearch = state.chatsSearch.conversations.find(
        (chat) => chat.id === chatId
      );
      const msgIndex = state.messages.findIndex(
        (msg) => msg.id === action.payload.id
      );
      const msgIndexConsultation = state.consultationsMessages.findIndex(
        (msg) => msg.id === action.payload.id
      );

      if (action.payload.showInConsultation) {
        if (msgIndexConsultation !== -1) {
          state.consultationsMessages[msgIndex] = {
            ...state.consultationsMessages[msgIndex],
            ...action.payload,
          };
        } else {
          state.consultationsMessages.unshift(action.payload);
        }
      }

      if (msgIndex !== -1) {
        state.messages[msgIndex] = {
          ...state.messages[msgIndex],
          ...action.payload,
        };
      }
      if (chat && chat?.lastMessage?.id === action.payload.id) {
        chat.lastMessage = {
          ...chat.lastMessage,
          ...action.payload,
        };
      }

      if (
        chatConsultations &&
        action.payload.showInConsultation &&
        new Date(chatConsultations?.lastMessage?.created) <
          new Date(action.payload.created)
      ) {
        chatConsultations.lastMessage = {
          ...chatConsultations.lastMessage,
          ...action.payload,
        };
        chatConsultations.lastConsultationDate =
          action.payload.conversation.lastConsultationDate;
        chatConsultations.consultationCount =
          action.payload.conversation.consultationCount;
      }
      if (chatSearch && chatSearch?.lastMessage?.id === action.payload.id) {
        chatSearch.lastMessage = {
          ...chatSearch.lastMessage,
          ...action.payload,
        };
      }
    },
    updateConversationTypes(state, action: PayloadAction<Chat>) {
      const chat = state.chats.find((chat) => chat.id === action.payload?.id);

      const chatSearch = state.chatsSearch.conversations.find(
        (chat) => chat.id === action.payload?.id
      );
      if (chat) {
        chat.conversations_types = action.payload.conversations_types;
      }
      if (chatSearch) {
        chatSearch.conversations_types = action.payload.conversations_types;
      }
    },

    updateMsgAfterSend(
      state,
      action: PayloadAction<Message & { localId: any }>
    ) {
      const chat = state.chats.find(
        (chat) => chat.id === action.payload?.conversation?.id
      );
      const chatConsultation = state.consultations.find(
        (chat) => chat.id === action.payload?.conversation?.id
      );
      const chatSearch = state.chatsSearch.conversations.find(
        (chat) => chat.id === action.payload?.conversation?.id
      );
      const msgIndex = state.messages.findIndex(
        (msg) => msg.id === action.payload.localId
      );
      const msgConsultationsIndex = state.consultationsMessages.findIndex(
        (msg) => msg.id === action.payload.localId
      );
      if (msgConsultationsIndex !== -1) {
        state.consultationsMessages[msgIndex] = {
          ...state.consultationsMessages[msgIndex],
          ...action.payload,
        };
      }
      if (msgIndex !== -1) {
        state.messages[msgIndex] = {
          ...state.messages[msgIndex],
          ...action.payload,
        };
      }
      if (chatConsultation && action.payload.showInConsultation) {
        if (action.payload.localId === chatConsultation.lastMessage?.id) {
          chatConsultation.lastMessage = {
            ...chatConsultation.lastMessage,
            ...action.payload,
          };
        }
        chat.endSession = action.payload.conversation?.endSession;
      }
      if (chat) {
        if (action.payload.localId === chat.lastMessage?.id) {
          chat.lastMessage = {
            ...chat.lastMessage,
            ...action.payload,
          };
        }
        chat.endSession = action.payload.conversation?.endSession;
      }
      if (chatSearch) {
        if (action.payload.localId === chatSearch.lastMessage?.id) {
          chatSearch.lastMessage = {
            ...chatSearch.lastMessage,
            ...action.payload,
          };
        }
        chatSearch.endSession = action.payload.conversation?.endSession;
      }
    },
    setLocalUrl(
      state,
      action: PayloadAction<{
        messageId: number;
        localUrl: string;
      }>
    ) {
      const msg = state.messages.find(
        (msg) => msg.id === action.payload.messageId
      );
      if (msg) {
        msg.localUrl = action.payload.localUrl;
      }
    },
    setSearch(state, action: PayloadAction<string>) {
      state.filters.search = action.payload;
      if (!action.payload) {
        state.chatsSearch.conversations = [];
      }
    },
    setFilterUnRead(state, action: PayloadAction<boolean>) {
      state.filters.filterUnRead = action.payload;
      if (!action.payload) {
        state.chatsSearch.conversations = [];
      }
    },
    handleFinishedChat(state, action: PayloadAction<Chat>) {
      state.chats = state.chats.filter((chat) => chat.id !== action.payload.id);
      if (state.activeChat?.id === action.payload.id) {
        state.activeChat = undefined;
      }
    },
    clearSearchData(state) {
      state.chatsSearch = {
        contacts: [],
        conversations: [],
        messages: [],
      };
    },
    clearSearchDisable(state) {
      state.disableChatSearchFectch = {
        disableChatSearchFectch: false,
        disableContactsSearchFectch: false,
        disableMessagesSearchFectch: false,
      };
    },
    clearConsultationSearchDisable(state) {
      state.disableConsultationsSearchFectch = {
        disableChatSearchFectch: false,
        disableContactsSearchFectch: false,
        disableMessagesSearchFectch: false,
      };
    },
    deleteChatTag(state, action: PayloadAction<Tag>) {
      const chatsTag = state.chats.filter((chat) =>
        chat?.contact?.tags?.some((tag) => tag.id === action.payload.id)
      );
      chatsTag.forEach((chat) => {
        const index = state.chats.findIndex((c) => chat.id === c.id);
        if (index !== -1) {
          chat.contact.tags =
            chat?.contact?.tags.filter((tag) => tag.id !== action.payload.id) ??
            [];
        }

        const activeChat = state.activeChat;
        if (
          activeChat &&
          activeChat?.contact?.tags?.some((tag) => tag.id === action.payload.id)
        ) {
          activeChat.contact.tags =
            activeChat?.contact?.tags.filter(
              (tag) => tag.id !== action.payload.id
            ) ?? [];
        }
      });
    },
    updateChatTag(state, action: PayloadAction<Tag>) {
      const chatsTag = state.chats.filter((chat) =>
        chat?.contact?.tags?.some((tag) => tag.id === action.payload.id)
      );
      chatsTag.forEach((chat) => {
        const index = state.chats.findIndex((c) => chat.id === c.id);
        if (index !== -1) {
          chat.contact.tags =
            chat?.contact?.tags.map((tag) =>
              tag.id === action.payload.id ? action.payload : tag
            ) ?? [];
        }

        const activeChat = state.activeChat;
        if (
          activeChat &&
          activeChat?.contact?.tags?.some((tag) => tag.id === action.payload.id)
        ) {
          activeChat.contact.tags =
            activeChat?.contact?.tags?.map((tag) =>
              tag.id === action.payload.id ? action.payload : tag
            ) ?? [];
        }
      });
    },
    markConsultationAsReplied(state, action: PayloadAction<Consultation>) {
      const messages = state.consultationsMessages.filter(
        (message) => message?.consultation?.id === action.payload.id
      );

      messages.forEach((message) => {
        const index = state.consultationsMessages.findIndex(
          (msg) => msg.id === message.id
        );
        state.consultationsMessages[index].consultation.isReplied = true;
      });
      const chat = state.consultations.find(
        (c) => c.contact.waId === action.payload.contact.waId
      );
      if (chat) {
        chat.consultationCount -= 1;
      }
    },
    setMetaTag(state, action: PayloadAction<string>) {
      const activeChat = state.activeChat;
      const activeChatConsultation = state.activeChatConsultation;
      if (activeChat) {
        activeChat.metaTag = action.payload;
      }
      if (activeChatConsultation) {
        activeChatConsultation.metaTag = action.payload;
      }
    },
    replaceConversations(
      state,
      action: PayloadAction<{
        oldConversation: Chat;
        newConversation: Chat;
      }>
    ) {
      const data = action.payload;
      state.chats = state.chats.filter(
        (c) =>
          ![data.oldConversation.id, data.newConversation.id].includes(c.id)
      );
      state.chats.push(data.newConversation);
      if (state.activeChat?.id === data.oldConversation.id) {
        state.activeChat = data.newConversation;
      }
    },
    updatePost(state, action: PayloadAction<Post>) {
      const chat = state.chats.find((c) => c.post_id === action.payload.id);

      if (chat) {
        chat.post_data = action.payload;
      }
      const activeChat = state.activeChat;
      if (activeChat?.post_id === action.payload.id) {
        activeChat.post_data = action.payload;
      }
    },
    updatePostReaction(
      state,
      action: PayloadAction<CommentReaction & { post_id: string }>
    ) {
      const { post_id, ...reaction } = action.payload;

      const chat = state.chats.find((c) => c.post_id === post_id);

      if (chat) {
        chat.total_reactions = reaction;
      }
      const activeChat = state.activeChat;
      if (activeChat?.post_id === post_id) {
        activeChat.total_reactions = reaction;
      }
    },
    updateCommentReactions(
      state,
      action: PayloadAction<CommentReaction & { comment_id: string }>
    ) {
      const { comment_id, ...reaction } = action.payload;

      const msg = state.messages.find((c) => c.meta_message_id === comment_id);
      if (msg) {
        msg.total_reactions = reaction;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMsgsConsultationsApi.pending, (state) => {
        state.loadingConsultationsMessages = true;
      })
      .addCase(getMsgsConsultationsApi.fulfilled, (state, action) => {
        state.loadingConsultationsMessages = false;
        const chat = state.consultations.find(
          (chat) => chat.id === action.payload.activeChatId
        );

        state.consultationsMessages = [
          ...state.consultationsMessages,
          ...action.payload.msgs.data,
        ];

        if (action.payload.msgs.data.length < 20) {
          if (chat) chat.disableFetch = true;
          if (state.activeChat) state.activeChat.disableFetch = true;
        }
      })
      .addCase(getMsgsApi.pending, (state) => {
        state.loadingMessages = true;
      })
      .addCase(getMsgsApi.fulfilled, (state, action) => {
        state.loadingMessages = false;
        const chat = state.chats.find(
          (chat) => chat.id === action.payload.activeChat?.id
        );

        state.messages = [...state.messages, ...action.payload.msgs.data];

        if (action.payload.msgs.data.length < 20) {
          if (chat) chat.disableFetch = true;
          if (state.activeChat) state.activeChat.disableFetch = true;
        }
      })
      .addCase(getMsgsApi.rejected, (state) => {
        state.loadingMessages = false;
        const chat = state.chats.find(
          (chat) => chat.contact.waId === state.activeChat?.contact?.waId
        );
        if (chat) {
          chat.disableFetch = true;
        }
      })
      .addCase(getSubCommentsApi.fulfilled, (state, action) => {
        // state.loadingMessages = false;
        const chatMsg = state.messages.find(
          (msg) => msg.meta_message_id === action.payload.parentId
        );

        state.messages = [...state.messages, ...action.payload.msgs.data];

        chatMsg.showSubComments = true;
        if (action.payload.msgs.data.length < 20) {
          if (chatMsg) chatMsg.disableFetchSubComments = true;
          // if (state.activeChat) state.activeChat.disableFetch = true;
        }
      })

      .addCase(getChatsApi.pending, (state) => {
        state.chatsLoading = true;
      })
      .addCase(getChatsApi.fulfilled, (state, action) => {
        state.chats = [
          ...state.chats,
          ...action.payload.data.map((chat) => ({
            ...chat,
            messages: [],
          })),
        ];
        if (action.payload.data.length < 20) {
          state.disableChatFectch = true;
        } else {
          state.disableChatFectch = false;
        }
        state.chatsLoading = false;
      })
      .addCase(getConsultationsApi.pending, (state) => {
        state.consultationsLoading = true;
      })
      .addCase(getConsultationsApi.fulfilled, (state, action) => {
        state.consultations = [
          ...state.consultations,
          ...action.payload.data.map((chat) => ({
            ...chat,
            messages: [],
          })),
        ];
        if (action.payload.data.length < 20) {
          state.disableConsultationsFectch = true;
        } else {
          state.disableConsultationsFectch = false;
        }
        state.consultationsLoading = false;
      })

      .addCase(getSearchChatsContactsApi.pending, (state) => {
        state.chatsLoading = true;
      })
      .addCase(getSearchChatsContactsApi.fulfilled, (state, action) => {
        if (
          state.filters.pageChatsSearch === 1 &&
          state.filters.pageContactsSearch === 1 &&
          state.filters.pageMessagesSearch === 1
        ) {
          state.chatsSearch = action.payload.data;
        } else {
          state.chatsSearch = {
            contacts: [
              ...state.chatsSearch.contacts,
              ...action.payload.data.contacts,
            ],
            conversations: [
              ...state.chatsSearch.conversations,
              ...action.payload.data.conversations,
            ],
            messages: [
              ...state.chatsSearch.messages,
              ...action.payload.data.messages,
            ],
          };
        }
        if (action.payload.data.conversations.length < 20) {
          state.disableChatSearchFectch.disableChatSearchFectch = true;
        } else {
          state.disableChatSearchFectch.disableChatSearchFectch = false;
        }
        if (action.payload.data.contacts.length < 20) {
          state.disableChatSearchFectch.disableContactsSearchFectch = true;
        } else {
          state.disableChatSearchFectch.disableContactsSearchFectch = false;
        }
        if (action.payload.data.messages.length < 20) {
          state.disableChatSearchFectch.disableMessagesSearchFectch = true;
        } else {
          state.disableChatSearchFectch.disableMessagesSearchFectch = false;
        }
        state.chatsLoading = false;
      })
      .addCase(getSearchChatsMessagesConsultationsApi.pending, (state) => {
        state.chatsLoading = true;
      })
      .addCase(
        getSearchChatsMessagesConsultationsApi.fulfilled,
        (state, action) => {
          if (
            state.filtersConsultations.pageChatsSearch === 1 &&
            state.filtersConsultations.pageContactsSearch === 1 &&
            state.filtersConsultations.pageMessagesSearch === 1
          ) {
            state.consultationsSearch = action.payload.data;
          } else {
            state.consultationsSearch = {
              contacts: [
                ...state.consultationsSearch.contacts,
                ...action.payload.data.contacts,
              ],
              conversations: [
                ...state.consultationsSearch.conversations,
                ...action.payload.data.conversations,
              ],
              messages: [
                ...state.consultationsSearch.messages,
                ...action.payload.data.messages,
              ],
            };
          }
          if (action.payload.data.conversations.length < 20) {
            state.disableConsultationsSearchFectch.disableChatSearchFectch =
              true;
          } else {
            state.disableConsultationsSearchFectch.disableChatSearchFectch =
              false;
          }
          if (action.payload.data.contacts.length < 20) {
            state.disableConsultationsSearchFectch.disableContactsSearchFectch =
              true;
          } else {
            state.disableConsultationsSearchFectch.disableContactsSearchFectch =
              false;
          }
          if (action.payload.data.messages.length < 20) {
            state.disableConsultationsSearchFectch.disableMessagesSearchFectch =
              true;
          } else {
            state.disableConsultationsSearchFectch.disableMessagesSearchFectch =
              false;
          }
          state.chatsLoading = false;
        }
      )
      .addCase(finishedChat.fulfilled, (state, action) => {
        state.chats = state.chats.filter(
          (c) => c.id !== action.payload.data.id
        );
        if (state.activeChat?.id === action.payload.data.id) {
          state.activeChat = undefined;
        }
      })
      .addCase(getChatByPhoneApi.fulfilled, (state, action) => {
        state.activeChat = action.payload;
        if (action.payload.showInConsultation) {
          state.activeChatConsultation = action.payload;
        }
      })
      .addCase(getChatByPhoneApi.rejected, (state, action) => {
        const id = uuidv4() as unknown as number;

        const chatData = {
          contact: action.payload,
          name: "",
          messages: [],
          id,
        } as Chat;

        // state.chats.unshift(chatData)
        state.activeChat = chatData;
      })
      .addCase(
        putContact.fulfilled,
        (state, action: PayloadAction<Contact>) => {
          const index = state.chats.findIndex(
            (chat) => chat?.contact?.waId === action.payload.waId
          );

          if (index !== -1) {
            state.chats[index].contact = action.payload;
            const activeChat = state.activeChat;
            if (activeChat && activeChat.contact.waId === action.payload.waId) {
              activeChat.contact = { ...activeChat.contact, ...action.payload };
            }
          }
          const indexConsultations = state.consultations.findIndex(
            (chat) => chat?.contact?.waId === action.payload.waId
          );
          if (indexConsultations !== -1) {
            state.consultations[index].contact = action.payload;
            const activeChat = state.activeChatConsultation;
            if (activeChat && activeChat.contact.waId === action.payload.waId) {
              activeChat.contact = { ...activeChat.contact, ...action.payload };
            }
          }
        }
      )
      .addCase(handleConsultationAnswered.fulfilled, (state, action) => {
        const messages = state.consultationsMessages.filter(
          (message) => message?.consultation?.id === action.payload.data.id
        );

        messages.forEach((message) => {
          const index = state.consultationsMessages.findIndex(
            (msg) => msg.id === message.id
          );
          state.consultationsMessages[index].consultation.isReplied = true;
        });
        const chat = state.consultations.find(
          (c) => c.contact.waId === action.payload.data.contact.waId
        );
        if (chat) {
          chat.consultationCount -= 1;
        }
      });
  },
});

const scrollToBottom = () => {
  const container = document.getElementById("messages-container");
  if (container) container.scrollTop = 0;
};

export const addNewMessage =
  (msg: Partial<Message>): AppThunk =>
  (dispatch, getState) => {
    const phone = userPhone(getState());
    const messenger = messengerId(getState());
    const instagram = instagramId(getState());
    const chat = activeChat(getState());
    const user = selectUser(getState());
    const session = new Date(chat!.endSession!);
    const now = new Date();
    let message_from, to;

    switch (chat.product) {
      case "whatsapp":
        message_from = phone;
        to = chat?.contact.waId;
        break;
      case "messenger":
        message_from = messenger;
        to = chat?.contact.messenger_id;
        break;
      case "instagram":
        message_from = instagram;
        to = chat?.contact.instagram_id;
        break;
    }

    if (session > now || chat.metaTag) {
      scrollToBottom();
      dispatch(
        addMessage({
          ...msg,
          message_from,
          id: uuidv4() as unknown as number,
          to,
          replyFor: msg.replyFor ? msg.replyFor : chat?.replyFor,
          conversation: chat,
          sendBy: user,
          product: chat.product,
          metaTag: chat.metaTag,
        })
      );
    }
  };
export const addNewComment =
  (msg: Partial<Message>): AppThunk =>
  (dispatch, getState) => {
    let message_from, to, parent_comment_id;
    const messenger = messengerId(getState());
    const instagram = instagramId(getState());
    const chat = activeChat(getState());
    const user = selectUser(getState());
    const replyFor = chat?.replyFor;

    switch (chat.product) {
      case "facebook_post":
        message_from = messenger;
        to = replyFor ? replyFor.meta_message_id : chat.post_id;
        break;
    }

    const targetmsg = chatMessages(getState()).find(
      (m) => m.meta_message_id === to
    );
    if (targetmsg) {
      if (targetmsg.parent_comment_id === chat.post_id) {
        parent_comment_id = targetmsg.meta_message_id;
      } else {
        parent_comment_id = targetmsg.parent_comment_id;
      }
    } else {
      parent_comment_id = chat.post_id;
    }

    // scrollToBottom();

    dispatch(
      addMessage({
        ...msg,
        message_from,
        id: uuidv4() as unknown as number,
        to,
        replyFor: msg.replyFor ? msg.replyFor : chat?.replyFor,
        conversation: chat,
        parent_comment_id,
        sendBy: user,
        product: "facebook_comment",
      })
    );
  };
export const addNewSmsMessage =
  (msg: Partial<Message>): AppThunk =>
  (dispatch, getState) => {
    const chat = activeChat(getState());
    const user = selectUser(getState());
    const msgs = chatMessages(getState());

    const replyFor = msg.replyFor ? msg.replyFor : chat?.replyFor;
    let sms_shortcode;
    let sms_lang;
    if (replyFor) {
      sms_shortcode = replyFor.sms_shortcode;
      sms_lang = replyFor.sms_lang;
    } else {
      sms_shortcode = msgs?.[0]?.sms_shortcode;
      sms_lang = msgs?.[0]?.sms_lang;
    }

    scrollToBottom();
    dispatch(
      addMessage({
        ...msg,
        message_from: "me",
        id: uuidv4() as unknown as number,
        to: chat?.contact.waId,
        replyFor: replyFor,
        conversation: chat,
        sendBy: user,
        product: "sms",
        sms_shortcode,
        sms_lang,
        showInConsultation: true,
      })
    );
  };

export const addNewMessageConsultation =
  (msg: Partial<Message>): AppThunk =>
  (dispatch, getState) => {
    const phone = userPhone(getState());
    const messenger = messengerId(getState());
    const instagram = instagramId(getState());
    const chat = activeChatConsultation(getState());
    const user = selectUser(getState());
    const session = new Date(chat!.endSession!);
    const now = new Date();

    let message_from, to;

    switch (chat.product) {
      case "whatsapp":
        message_from = phone;
        to = chat?.contact.waId;
        break;
      case "messenger":
        message_from = messenger;
        to = chat?.contact.messenger_id;
        break;
      case "instagram":
        message_from = instagram;
        to = chat?.contact.instagram_id;
        break;
    }

    if (session > now || chat.metaTag) {
      scrollToBottom();
      dispatch(
        addMessage({
          ...msg,
          message_from,
          id: uuidv4() as unknown as number,
          to,
          replyFor: msg.replyFor ? msg.replyFor : chat?.replyFor,
          conversation: chat,
          sendBy: user,
          showInConsultation: true,
          product: "whatsapp",
        })
      );
    }
  };

export const addNewSmsMessageConsultation =
  (msg: Partial<Message>): AppThunk =>
  (dispatch, getState) => {
    const chat = activeChatConsultation(getState());
    const user = selectUser(getState());
    const msgs = chatConsultationMessages(getState());

    const replyFor = msg.replyFor ? msg.replyFor : chat?.replyFor;
    let sms_shortcode;
    let sms_lang;
    if (replyFor) {
      sms_shortcode = replyFor.sms_shortcode;
      sms_lang = replyFor.sms_lang;
    } else {
      sms_shortcode = msgs?.[0]?.sms_shortcode;
      sms_lang = msgs?.[0]?.sms_lang;
    }
    scrollToBottom();
    dispatch(
      addMessage({
        ...msg,
        message_from: "me",

        id: uuidv4() as unknown as number,
        to: chat?.contact.waId,
        replyFor: replyFor,
        conversation: chat,
        sendBy: user,
        showInConsultation: true,
        product: "sms",
        sms_shortcode,
        sms_lang,
      })
    );
  };

export const sendCompanyMsg =
  (msg: {
    msg: Partial<CompanyMessages>;
    variables?: any;
    metaTag?: string;
  }): AppThunk =>
  (dispatch, getState) => {
    const phone = userPhone(getState());
    const chat = activeChat(getState());
    const messenger = messengerId(getState());
    const instagram = instagramId(getState());

    const session = new Date(chat!.endSession!);
    const now = new Date();
    let message_from, to;

    switch (chat.product) {
      case "whatsapp":
        message_from = phone;
        to = chat?.contact.waId;
        break;
      case "messenger":
        message_from = messenger;
        to = chat?.contact.messenger_id;
        break;
      case "instagram":
        message_from = instagram;
        to = chat?.contact.instagram_id;
        break;
    }
    if (session > now || msg.msg.msgtype === "template" || chat.metaTag) {
      scrollToBottom();

      socket.emit(
        "sendCompanyMsg",
        {
          id: msg.msg.id,
          message_from,
          to,
          variables: msg?.variables,
          product: chat?.product,
          metaTag: chat.metaTag,
        },
        (val: any) => {
          dispatch(addCompanyMessage({ ...val }));
        }
      );
    }
  };

export const sendTemplateMsg =
  ({
    msg,
    variables,
  }: {
    msg: Partial<CompanyMessages>;
    variables?: any;
  }): AppThunk =>
  (dispatch, getState) => {
    const phone = userPhone(getState());
    const chat = activeChat(getState());
    const messenger = messengerId(getState());
    const instagram = instagramId(getState());

    let message_from, to;

    switch (chat.product) {
      case "whatsapp":
        message_from = phone;
        to = chat?.contact.waId;
        break;
      case "messenger":
        message_from = messenger;
        to = chat?.contact.messenger_id;
        break;
      case "instagram":
        message_from = instagram;
        to = chat?.contact.instagram_id;
        break;
    }
    scrollToBottom();

    socket.emit(
      "sendTemplateMsg",
      {
        id: msg.id,
        message_from,
        to,
        variables,
        product: chat?.product,
      },
      (val: any) => {
        dispatch(addCompanyMessage({ ...val }));
      }
    );
  };

export const openSideNav = (state: RootState) => state.chat.openSideNav;

export const msgModal = (state: RootState) =>
  state.chat.messages.find((msg) => msg.id === state.chat.msgModal);

export const chats = (state: RootState) => state.chat.chats;
export const chatsSearch = (state: RootState) => state.chat.chatsSearch;
export const consultationsSearch = (state: RootState) =>
  state.chat.consultationsSearch;
export const contactsSearch = (state: RootState) => state.chat.contactsSearch;

export const activeChat = (state: RootState) => state.chat.activeChat;
export const activeChatConsultation = (state: RootState) =>
  state.chat.activeChatConsultation;

export const searchValue = (state: RootState) => state.chat.filters.search;

export const messages = (state: RootState) => state.chat.messages;
export const consultationsMessages = (state: RootState) =>
  state.chat.consultationsMessages;
export const consultationsList = (state: RootState) => state.chat.consultations;

export const chatFilters = (state: RootState) => state.chat.filters;
export const filtersConsultations = (state: RootState) =>
  state.chat.filtersConsultations;

const selectParentId = (_state: RootState, parentId: string) => parentId;

export const assignedUser = createSelector([activeChat], (activeChat) => {
  return activeChat?.handledBy ?? [];
});
export const disableFetch = createSelector(
  [chats, activeChat],
  (chats, activeChat) => {
    return chats.find((chat) => chat.id === activeChat?.id)?.disableFetch;
  }
);
export const disableFetchConsultation = createSelector(
  [consultationsList, activeChatConsultation],
  (chats, activeChat) => {
    return chats.find((chat) => chat.id === activeChat?.id)?.disableFetch;
  }
);

export const activeChatContactData = createSelector(
  [activeChat],
  (activeChat) => {
    if (activeChat) return activeChat.contact;
  }
);
export const chatMessages = createSelector(
  [messages, activeChat],
  (messages, activeChat) => {
    return messages.filter((msg) => msg.conversation?.id === activeChat?.id);
  }
);

export const chatCommentsById = createSelector(
  [messages, selectParentId],
  (messages, selectParentId) => {
    return messages.filter((msg) => msg.parent_comment_id === selectParentId);
  }
);
// export const chatCommentsById = (state: RootState, parentId: string) =>
//   state.chat.messages.filter((msg) => msg.parent_comment_id === parentId);

export const chatConsultationMessages = createSelector(
  [consultationsMessages, activeChatConsultation],
  (consultationsMessages, activeChatConsultation) => {
    return consultationsMessages
      .filter((msg) => msg.conversation?.id === activeChatConsultation?.id)
      .sort((a, b) => {
        const lastMessageA = a.created;
        const lastMessageB = b.created;
        // Check if both messages exist
        if (lastMessageA && lastMessageB) {
          return (
            new Date(lastMessageB ?? "").getTime() -
            new Date(lastMessageA ?? "").getTime()
          );
        } else if (!lastMessageA && !lastMessageB) {
          // Both messages are undefined, consider them equal
          return 0;
        } else if (!lastMessageA) {
          // Only lastMessageA is undefined, consider it less than lastMessageB
          return -1;
        } else {
          // Only lastMessageB is undefined, consider it greater than lastMessageA
          return 1;
        }
      });
  }
);

export const replyMsg = createSelector(
  [chats, activeChat],
  (chats, activeChat) => {
    const chat = chats.find((chat) => chat.id === activeChat?.id);
    if (chat) return chat.replyFor;
  }
);
export const replyMsgConsultation = createSelector(
  [consultationsList, activeChatConsultation],
  (chats, activeChat) => {
    const chat = chats.find((chat) => chat.id === activeChat?.id);
    if (chat) return chat.replyFor;
  }
);

export const chatByMsgPhone = (state: RootState, phone: string) => {
  return state.chat.chats.find((chat) => chat.contact?.waId === phone);
};

export const selectedFiles = (state: RootState) => state.chat.selectedFiles;

export const menuSelected = (state: RootState) => state.chat.menuSelected;

export const msgInView = (state: RootState) => state.chat.msgInView;
export const searchType = (state: RootState) => state.chat.searchType;

export const filterUnRead = (state: RootState) =>
  state.chat.filters.filterUnRead;

export const loadingFetchMsgs = (state: RootState) =>
  state.chat.loadingMessages;
export const loadingConsultationsMessages = (state: RootState) =>
  state.chat.loadingConsultationsMessages;
export const chatsLoading = (state: RootState) => state.chat.chatsLoading;
export const disableChatFectch = (state: RootState) =>
  state.chat.disableChatFectch;
export const disableChatSearchFectch = (state: RootState) =>
  state.chat.disableChatSearchFectch;
export const disableConsultationsSearchFectch = (state: RootState) =>
  state.chat.disableConsultationsSearchFectch;

// export const contactsTags = (state: RootState) => {
//     const contacts = state.chat.contacts.flatMap((contact) => contact.tags)
//     return [...new Set(contacts)]
// }

export const isFirstGroubMsg = (state: RootState, msgId: number) => {
  const messages = chatMessages(state);
  const i = messages.findIndex((msg) => msg.id === msgId) as number;
  const msg = messages.find((msg) => msg.id === msgId);

  return (
    i === 0 ||
    (messages[i - 1] && messages[i - 1].message_from !== msg.message_from)
  );
};

export const isLastGroubMsg = (state: RootState, msgId: number) => {
  const messages = chatMessages(state);
  const msg = messages.find((msg) => msg.id === msgId);
  const i = messages.findIndex((msg) => msg.id === msgId) as number;

  return (
    i === messages.length - 1 ||
    (messages[i + 1] && messages[i + 1].message_from !== msg.message_from)
  );
};

export const isLastConsultationsGroubMsg = (
  state: RootState,
  msgId: number
) => {
  const messages = chatConsultationMessages(state);
  const msg = messages.find((msg) => msg.id === msgId);
  const i = messages.findIndex((msg) => msg.id === msgId) as number;

  return (
    i === messages.length - 1 ||
    (messages[i + 1] && messages[i + 1].message_from !== msg.message_from)
  );
};

export const isFirstConsultationsGroubMsg = (
  state: RootState,
  msgId: number
) => {
  const messages = chatConsultationMessages(state);
  const i = messages.findIndex((msg) => msg.id === msgId) as number;
  const msg = messages.find((msg) => msg.id === msgId);

  return (
    i === 0 ||
    (messages[i - 1] && messages[i - 1].message_from !== msg.message_from)
  );
};

export const showTriangle = (state: RootState, msgId: number) => {
  const messages = chatMessages(state);
  const msgsLength = messages.length;
  const index = messages.findIndex((msg) => msg.id === msgId) as number;

  const msg = messages[index];
  let showMsgTriangle = true;
  if (msgsLength && msgsLength > 1 && index !== msgsLength - 1) {
    const date = new Date(msg?.created ?? "");
    const nextDate = new Date(messages[index + 1].created ?? "");
    const diff = differenceInCalendarDays(date, nextDate);

    if (
      (messages[index + 1].message_from === msg?.message_from && diff === 0) ||
      index === msgsLength - 1
    ) {
      showMsgTriangle = false;
    }
  }
  if (msg?.msgtype === "sticker") showMsgTriangle = false;
  return showMsgTriangle;
};

export const handleReciveMsg =
  (message: Message): AppThunk =>
  async (dispatch, getState) => {
    const phone = userPhone(getState());
    const user = selectUser(getState())!;
    dispatch(receiveMsg({ ...message, companyPhone: phone!, user }));
  };

export const {
  handleSideNav,
  setMsgModal,
  setActiveChat,
  addMessage,
  addReactionMsg,
  removeReaction,
  addChat,
  setReplayMsg,
  setFiles,
  setMenuSelected,
  setMsgInView,
  setAssignTo,
  setChats,
  receiveMsg,
  zeroingUnReadMsgsChat,
  updateMsg,
  addCompanyMessage,
  updateMsgAfterSend,
  updateChatContact,
  setLocalUrl,
  setSearch,
  conversationAssigned,
  setFilterUnRead,
  setChatFilters,
  setSearchType,
  conversationUnAssigned,
  handleFinishedChat,
  updateChatBotReplt,
  clearSearchData,
  clearSearchDisable,
  updateChatTag,
  deleteChatTag,
  setactiveChatConsultation,
  setConsultationsFilters,
  clearConsultationSearchDisable,
  markConsultationAsReplied,
  updateConversationTypes,
  setMetaTag,
  replaceConversations,
  updatePost,
  updateCommentReactions,
  updatePostReaction,
} = chatSlice.actions;
export default chatSlice.reducer;
