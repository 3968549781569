import { darken, styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { selectUser } from "@@app/store/slices/userSlice";
import { useAppSelector } from "app/store/hooks";
import { companyProfile } from "@@app/store/slices/companyProfile";

const Root = styled("div")(({ theme }) => ({
  "& .username, & .email": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  "& .avatar": {
    background: darken(theme.palette.background.default, 0.05),
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    "& > img": {
      borderRadius: "50%",
    },
  },
}));

/**
 * The user navbar header.
 */
function UserNavbarHeader() {
  const user = useAppSelector(selectUser);
  const profile = useAppSelector(companyProfile);

  return (
    <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
      <div className="mb-24 flex items-center justify-center">
        {/* {profile?.profile_picture_url ? (
          <img
            src={profile.profile_picture_url}
            alt=""
            className="h-96 w-96 object-contain"
          />
        ) : (
          <Avatar
            sx={{
              backgroundColor: "background.paper",
              color: "text.secondary",
              objectFit: "contain",
            }}
            className="avatar uppercase h-96 w-96 text-32 font-bold"
            src={profile.profile_picture_url}
            alt={user?.username}
          >
            {user?.username?.charAt(0)}
          </Avatar>
        )} */}
        <Avatar
          sx={{
            color: "text.secondary",
            objectFit: "contain",
          }}
          className="avatar uppercase h-96 w-96 text-32 font-bold"
          src={profile?.whatsapp?.profile_picture_url}
          alt={user?.username}
        >
          {user?.username?.charAt(0)}
        </Avatar>
      </div>
      <Typography className="username whitespace-nowrap text-14 font-medium">
        {user?.username}
      </Typography>
      <Typography
        className="email whitespace-nowrap text-13 font-medium"
        color="text.secondary"
      >
        {user?.email}
      </Typography>
    </Root>
  );
}

export default UserNavbarHeader;
