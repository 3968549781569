import { useGetByCommentIdQuery } from "@src/app/store/services/contacts";
import { memo } from "react";

const CommentTag = ({ id }: { id: string }) => {
  const { data, isFetching, isError } = useGetByCommentIdQuery(id);
  return (
    <span className="text-blue-600 underline">
      @{isError ? `[${id}]` : data?.messenger_name}
    </span>
  );
};

export default memo(CommentTag);
